import { fnumber } from "helper/Helper";
// import secureStorage from "libraries/secureStorage";

const loader = (title, leftPage, middlePage, rightPage, payload) => {
  // const info = secureStorage.getItem("domainInfo");

  let checkNextline = [...leftPage];
  checkNextline.map((item, index) => {
    if (typeof item[1] === "string" && item[1].length > 15) {
      leftPage.splice(index, 1, [item[0], item[1].substring(0, 15)]);
    }
    return item;
  });
  checkNextline = [...middlePage];
  checkNextline.map((item, index) => {
    if (typeof item[1] === "string" && item[1].length > 16) {
      middlePage.splice(index, 1, [item[0], item[1].substring(0, 16)]);
    }
    return item;
  });
  checkNextline = [...rightPage];
  checkNextline.map((item, index) => {
    if (typeof item[1] === "string" && item[1].length > 20) {
      rightPage.splice(index, 1, [item[0], item[1].substring(0, 20)]);
    }
    return item;
  });

  const loader = [];
  leftPage.map((item) => {
    const line = Array.isArray(item)
      ? item.length > 1
        ? `${item[0].toString().padEnd(6, " ")}:${
            typeof item[1] !== "number" ? item[1] : `Rp.${fnumber(item[1]).padStart(11, " ")}`
          }`
        : item[0]
      : item;
    loader.push(line.padEnd(22, " "));
    return item;
  });
  const loader2 = [];
  // console.log(middlePage)
  middlePage.map((item, index) => {
    const line = Array.isArray(item)
      ? item.length > 1
        ? `${item[0].toString().padEnd(8, " ")}:${
            typeof item[1] !== "number" ? item[1] : `Rp.${fnumber(item[1]).padStart(11, " ")}`
          }`
        : item[0]
      : item;
    loader2.push(`${loader[index]}${line.padEnd(25, " ")}`);
    return item;
  });

  const loader3 = [];
  rightPage.map((item, index) => {
    const line = Array.isArray(item)
      ? item.length > 1
        ? `${item[0].toString().padEnd(12, " ")}:${
            typeof item[1] !== "number" ? item[1] : `Rp.${fnumber(item[1]).padStart(11, " ")}`
          }`
        : item[0]
      : item;
    // if(loader2[index].indexOf('NO REF')!==-1)
    // console.log(loader2[index].length)
    if (loader2[index].length < 70) loader3.push(`${loader2[index]}${line.padEnd(10, " ")}\r\n`);
    else loader3.push(`${loader2[index]}\r\n`);
    return item;
  });

  // const feed= payload.feed && payload.feed===true ?
  // [
  //     String.fromCharCode(10),
  //     String.fromCharCode(10),
  //     String.fromCharCode(13),
  // ] : []

  const ESC = String.fromCharCode(27); // ESC character
  const DOUBLE_STRIKE_ON = ESC + "G"; // ESC G to enable double-strike mode
  const DOUBLE_STRIKE_OFF = ESC + "H"; // ESC H to disable double-strike mode
  const MIN_SPACING = ESC + String.fromCharCode(32) + String.fromCharCode(0); // ESC SP 0 for minimum spacing
  const RESET = String.fromCharCode(27) + "@";
  const BOLD_ON = String.fromCharCode(27) + "E";
  const BOLD_OFF = String.fromCharCode(27) + "F";

  const final = [
    // 'LF',
    // 'LF',
    // '\r\n',
    // '\r\n',
    // ...feed,

    String.fromCharCode(27) + "2" + String.fromCharCode(14),
    String.fromCharCode(27) + "E" + title + String.fromCharCode(27) + "F\r\n",
    BOLD_OFF + DOUBLE_STRIKE_OFF,
    ...loader3,
    String.fromCharCode(27) + "0",
    String.fromCharCode(10),
    String.fromCharCode(10),
    String.fromCharCode(10),
    String.fromCharCode(13),
  ];

  // console.log(final);
  return final;
};
export default loader;
